import React from "react";
import {Slider} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import * as util from "../utils";

export const styles = {
    panel: {
        width: '100%',
        textAlign: 'left'
    }
};

const SliderWithLabel = ({title, min, max, formatter, value, setValue}) => {

    const theme = useTheme();

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>{title}</p>
                <p style={{color: theme.palette.primary.main, fontWeight: 700}}>{formatter(value)}</p>
            </div>
            <Slider value={value} onChange={(e, data) => setValue(data)} valueLabelDisplay="off" min={min} max={max}/>
        </div>
    )
};

const RangeSliderWithLabel = ({title, min, max, formatter, value, setValue}) => {

    const theme = useTheme();

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>{title}</p>
                <p style={{color: theme.palette.primary.main, fontWeight: 700}}>{formatter(value)}</p>
            </div>
            <Slider value={value} onChange={(e, data) => setValue(data)} valueLabelDisplay="off" min={min} max={max}/>
        </div>
    )
};

const DistanceSlider = ({value, setValue}) => {
    const distances = [1, 2, 3, 5, 10, 20, 50, 100, 200, 500, 1000, 1500, 2000, 3000, 5000, 10000, 15000, 20000, 50000, 100000];

    const format = metres => "less than " + (metres < 1000 ? `${metres}m` : `${Math.round(metres / 1000)}km`) + " apart";

    return <SliderWithLabel value={distances.indexOf(value)} setValue={e => setValue(distances[Math.round(e)])} max={distances.length - 1} min={0} title="Show us when we were..."
                            formatter={e => format(distances[Math.round(e)])}/>;
};

const TimeSlider = ({value, setValue}) => {
    const day = 60 * 24;
    const week = 7 * day;
    const year = 365 * day;
    const distances = [1, 2, 3, 5, 10, 20, 30, 60, 120, 180, 240, 360, 720, day, 3 * day, week, 4 * week, 26 * week, year, 3 * year, 5 * year, 20 * year];

    const format = minutes => "less than " + util.formatMinutes(minutes);

    return <SliderWithLabel value={distances.indexOf(value)} setValue={e => setValue(distances[Math.round(e)])} max={distances.length - 1} min={0} title="and missed each other by..."
                            formatter={e => format(distances[Math.round(e)])}/>;
};

const DateRangeSlider = ({setMinYear, setMaxYear, minYear, maxYear}) => {
    return <RangeSliderWithLabel value={[minYear, maxYear]} setValue={([e, f]) => {
        setMinYear(e);
        setMaxYear(f);
    }} max={2020} min={2013} title="between..." formatter={([s, e]) => s + " - " + e}/>
};

export const Filters = ({distanceFilter, timeFilter, setDistanceFilter, setTimeFilter, setMinYear, setMaxYear, minYear, maxYear}) => {
    return (
        <div style={styles.panel}>
            <DistanceSlider value={distanceFilter} setValue={setDistanceFilter}/>
            <TimeSlider value={timeFilter} setValue={setTimeFilter}/>
            <DateRangeSlider setMinYear={setMinYear} setMaxYear={setMaxYear} minYear={minYear} maxYear={maxYear}/>
        </div>
    );
};