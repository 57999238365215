import React from "react";
import {LogoBar} from "./Logo";

const style = {
    background: '#F5F5F5',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 32,
    flexDirection: 'column',
    width: 480,
    height: 'calc(100% - 180px)',
    textAlign: 'center'
};

const container = {
    borderLeft: '3px solid #CCC'
};

export const Panel = ({children}) => (
    <div style={container}>
        <LogoBar/>
        <div style={style}>
            {children}
        </div>
    </div>
);