export const formatMinutes = minutes => {
    const day = 60 * 24;
    const week = 7 * day;
    const year = 365 * day;

    if (minutes === 20 * year)
        return "forever";
    if (minutes < 1)
        return Math.round(minutes / 60) + " seconds";
    if (minutes < 60)
        return Math.round(minutes) + " mins";
    if (minutes < day)
        return Math.round(minutes / 60) + " hours";
    if (minutes < week)
        return Math.round(minutes / day) + " days";
    if (minutes < year)
        return Math.round(minutes / week) + " weeks";
    return Math.round(minutes / year) + " years";
};

export const formatMetres = metres => {
    if (metres < 1) {
        return "less than 1 metre";
    }
    if (metres < 1000) {
        return Math.round(metres) + " metres";
    }
    return Math.round(metres / 1000) + " km";
};