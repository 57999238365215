import GoogleMapReact from "google-map-react";
import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import interpolate from 'color-interpolate';


const AssetMarker = ({asset, clicked, distanceFilterKm}) => {

    const colors = interpolate(["#FF5A5F", "#E9C46A", "#0e7c7b"]);
    const color = colors(asset.distance / distanceFilterKm);

    return <div onClick={() => clicked(asset)} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', transform: 'translate(-50%, -100%)'}}>
        <div className="map-icon" style={{ background: color, opacity: 0.8}}>

        </div>
    </div>
};

export const Map = ({points, clicked, distanceFilterKm}) => {

    const options = (maps) => ({
        zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_CENTER,
            style: maps.ZoomControlStyle.SMALL
        },
        mapTypeControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: true,
        streetViewControl: true,
        rotateControl: true,
        scaleControl: true,
    });

    const loadedMap = ({map}) => {
        const bounds = new window.google.maps.LatLngBounds();
        if (points.length === 0) return;
        points.forEach(s => {
            let lat = s.lat;
            let lng = s.lng;
            bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        map.fitBounds(bounds);
    };

    return <div style={{height: '100%', width: '100%'}}>
        <GoogleMapReact
            bootstrapURLKeys={{key: 'AIzaSyD6Adurofeg5pGPtJkaAENqwnRMRZUpDU0'}}
            defaultCenter={{
                lat: 54,
                lng: -1.5
            }}
            defaultZoom={7}
            onGoogleApiLoaded={loadedMap}
            options={options}
            yesIWantToUseGoogleMapApiInternals>

            {points.map(e => <AssetMarker distanceFilterKm={distanceFilterKm} clicked={clicked} key={e.id} asset={e} lat={e.lat} lng={e.lng}/>)}

        </GoogleMapReact>
    </div>
};

export default Map;