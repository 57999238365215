import React, {useRef} from 'react';

export const useFileUpload = (accept, onSelected) => {

    const formRef = useRef(null);

    const formChanged = () => {
        onSelected(formRef.current[0].files[0]);
    };

    const openPicker = () => {
        formRef.current[0].click();
    };

    const component = (
        <form onChange={formChanged} ref={formRef} style={{display: 'none'}}>
            <input type="file" id="file" accept={accept}/>
        </form>
    );

    return [component, openPicker];
};