import React, {useEffect, useState} from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import * as utils from "../utils";
import {CSSTransition} from "react-transition-group";

const styles = {
    stylisedText: {
        fontWeight: 400
    },
    main: {
        fontSize: 38,
        fontFamily: 'Roboto',
        fontWeight: 300
    }
};

const Stylised = ({children}) => {
    const theme = useTheme();
    return <span style={{...styles.stylisedText, color: theme.palette.primary.main}}>{children}</span>
};

export const SelectedPointInformation = ({point, names}) => {

    if (point === null) {
        return (
            <div style={styles.main}>
                <p>Click on a point to see more</p>
            </div>
        );
    }

    const distance = utils.formatMetres(point.distance * 1000);
    const mins = utils.formatMinutes(point.timeDiff / 60);

    const laterText = point.timeDiff < 60 ? <Stylised>at the same time</Stylised> : <>about <Stylised>{mins}</Stylised> later</>;

    const firstName = names[point.wasPerson0First ? 0 : 1];
    const secondName = names[point.wasPerson0First ? 1 : 0];

    return (
        <div style={styles.main}>
            <p>{firstName} was here on <br/><Stylised>{point.date}</Stylised><br/><br/>{secondName} was<br/><Stylised>{distance}</Stylised> away<br/>{laterText}</p>
        </div>
    );
};