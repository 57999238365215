import {createMuiTheme} from '@material-ui/core/styles';

export const primary = "#23cf92";

export default createMuiTheme({
    palette: {
        primary: {
            main: primary,
            accent: '#0e7c7b'
        },
    }
});