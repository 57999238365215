import {primary} from "../Theme";
import React from "react";

export const Logo = () => {
    const size = 240;
    const padding = 8;
    const divStyle = {
        height: size,
        padding: '8px 32px',
        background: primary,
        borderRadius: (size + padding) / 4,
        display: 'flex',
        color: 'white',
        alignItems: 'flex-end',
        fontSize: 180,
        fontFamily: 'roboto',
        fontWeight: 200
    };
    const style = {width: size - padding, height: '100%'};
    return <div style={divStyle}>
        <img style={style} src={require("../assets/logo.png")}/>
        <p style={{marginBottom: -12}}>wined</p>
    </div>
};

export const LogoBar = () => {
    const size = 100;
    const divStyle = {
        height: size,
        width: 'calc(100% - 64px)',
        padding: '8px 32px',
        background: primary,
        display: 'flex',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 180,
        fontFamily: 'roboto',
        fontWeight: 200
    };
    const style = { height: '100%'};
    return <div style={divStyle}>
        <img style={style} src={require("../assets/logo.png")}/>
    </div>
};