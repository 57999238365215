import React, {useEffect, useState} from 'react';
import './App.css';
import Map from "./components/Map";
import {useFileUpload} from "./hooks/fileUpload";
import Button from "@material-ui/core/Button";
import moment from 'moment';
import {SelectedPointInformation} from "./components/SelectedPointInformation";
import {markersForFiles} from "./logic";
import {Filters} from "./components/Filters";
import {Panel} from "./components/Panel";
import {MuiThemeProvider} from "@material-ui/core/styles";
import theme, {primary} from './Theme';
import {LogoBar, Logo} from "./components/Logo";

const styles = {
    subtitle: {
        color: '#555',
    },
    step: {
        fontSize: 42,
        fontWeight: 700,
        color: primary
    }
};

const FileOrPicker = ({openFilePicker, file}) => {
    const hasFile = file !== null;
    return <Button variant="contained" color="primary" onClick={openFilePicker}>{hasFile ? "Change File" : "Select File"}</Button>
};

const readFile = (file, gotFile) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (evt) => gotFile(evt.target.result);
};

function App() {

    const [fileContents0, setFileContents0] = useState(null);
    const [fileContents1, setFileContents1] = useState(null);

    const [points, setPoints] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [filteredPoints, setFilteredPoints] = useState([]);

    const [distanceFilter, setDistanceFilter] = useState(50);
    const [timeFilter, setTimeFilter] = useState(60 * 24);
    const [minYear, setMinYear] = useState(2013);
    const [maxYear, setMaxYear] = useState(2020);

    const [file0, setFile0] = useState(null);
    const [file1, setFile1] = useState(null);

    const [filePicker, openFilePicker] = useFileUpload(".json", setFile0);
    const [filePicker1, openFilePicker1] = useFileUpload(".json", setFile1);

    const [step, setStep] = useState(0);

    const [personName0, setPersonName0] = useState("");
    const [personName1, setPersonName1] = useState("");

    const [loading, setLoading] = useState(false);

    const nextStep = () => {
        setStep(e => e + 1);
    };

    const go = () => {
        readFile(file0, setFileContents0);
        readFile(file1, setFileContents1);
    };

    const inputHandler = setter => event => setter(event.target.value);

    const step0 = (
        <>
            <p style={styles.step}>Step 1</p>
            <p>Head to <a href="https://takeout.google.com/settings/takeout" target="_blank">Google Takeout</a> and export your location history</p>
            <img style={{height: 360, border: 'solid 1px ' + primary, borderRadius: 16}} src={require("./assets/takeout.png")}/>
            <br/> <br/>
            <Button variant="contained" color="primary" style={{paddingLeft: 128, paddingRight: 128}} onClick={nextStep}>Next</Button>
        </>
    );

    const step1 = (
        <div style={{maxWidth: '20cm'}}>
            <p style={styles.step}>Step 2</p>
            <p>Select your and your friends history to see your twines</p>
            <div style={{border: 'solid 1px ' + primary, borderRadius: 16, padding: 32}}>
                <p style={{...styles.step, margin: 0, textAlign: 'left'}}>Person 1</p>
                <div style={{display: 'flex', marginTop: 32}}>
                    <FileOrPicker openFilePicker={openFilePicker} file={file0}/>
                    <div style={{width: 48}}/>
                    <input placeholder="Person's name" value={personName0} onChange={inputHandler(setPersonName0)} style={{width: 420}}/>
                </div>
            </div>
            <div style={{border: 'solid 1px ' + primary, borderRadius: 16, padding: 32, marginTop: 32}}>
                <p style={{...styles.step, margin: 0, textAlign: 'left'}}>Person 2</p>
                <div style={{display: 'flex', marginTop: 32}}>
                    <FileOrPicker openFilePicker={openFilePicker1} file={file1}/>
                    <div style={{width: 48}}/>
                    <input placeholder="Person's name" value={personName1} onChange={inputHandler(setPersonName1)} style={{width: 420}}/>
                </div>
            </div>
            <br/> <br/>
            <Button variant="contained" color="primary" style={{paddingLeft: 128, paddingRight: 128}} onClick={go}>Go</Button>
        </div>
    );

    const currentStep = [step0, step1][step];

    useEffect(() => {
        if (fileContents1 && fileContents0) {
            setLoading(true)
            setTimeout(() => {
                const markers = markersForFiles([fileContents0, fileContents1]);
                setPoints(markers)
                setLoading(false);
            }, 100)
        }
    }, [fileContents1, fileContents0]);

    useEffect(() => {

        const allowed = e => {
            if (e.timeDiff > timeFilter * 60) return false;
            const minYearUnix = moment("0101" + minYear, "DDMMYYYY").unix();
            const maxYearUnix = moment("0101" + (maxYear + 1), "DDMMYYYY").unix();
            return e.unix >= minYearUnix && e.unix < maxYearUnix;
        };

        setFilteredPoints(points.filter(allowed));
    }, [points, timeFilter, minYear, maxYear]);

    if (loading) return <LoadingScreen/>

    if (fileContents1 && fileContents0)
        return (
            <MuiThemeProvider theme={theme}>
                <div style={{display: 'flex', height: '100vh'}}>
                    <Map names={[personName0, personName1]} points={filteredPoints} distanceFilterKm={distanceFilter / 1000} clicked={setSelectedPoint}/>
                    <Panel>
                        <SelectedPointInformation names={[personName0, personName1]} point={selectedPoint}/>
                        <Filters distanceFilter={distanceFilter} maxYear={maxYear} minYear={minYear} setDistanceFilter={setDistanceFilter} setMinYear={setMinYear} setMaxYear={setMaxYear}
                                 setTimeFilter={setTimeFilter} timeFilter={timeFilter}/>
                    </Panel>
                </div>
            </MuiThemeProvider>
        );

    return (
        <MuiThemeProvider theme={theme}>
            <div style={{padding: 48, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {filePicker}
                {filePicker1}

                <Logo/>
                <p style={styles.subtitle}>Did you almost meet a few years earlier? Find your twined!</p>

                {currentStep}
            </div>
        </MuiThemeProvider>
    );
}

const AppA = () => {
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);

    const [points, setPoints] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [filteredPoints, setFilteredPoints] = useState([]);

    const [distanceFilter, setDistanceFilter] = useState(50);
    const [timeFilter, setTimeFilter] = useState(60 * 24);
    const [minYear, setMinYear] = useState(2013);
    const [maxYear, setMaxYear] = useState(2020);

    useEffect(() => {
        const jsonFile = new XMLHttpRequest();
        jsonFile.open("GET", "http://twineapp.com:3000/loc/hamzah.json", true);
        jsonFile.send();

        jsonFile.onreadystatechange = () => {
            if (jsonFile.readyState === 4 && jsonFile.status === 200) {
                setFile1(jsonFile.responseText);
            }
        };
    }, []);

    useEffect(() => {
        const jsonFile = new XMLHttpRequest();
        jsonFile.open("GET", "http://twineapp.com:3000/loc/jake.json", true);
        jsonFile.send();

        jsonFile.onreadystatechange = () => {
            if (jsonFile.readyState === 4 && jsonFile.status === 200) {
                setFile2(jsonFile.responseText);
            }
        };
    }, []);

    useEffect(() => {
        if (file1 && file2) {
            console.time("buckets");
            const markers = markersForFiles([file1, file2]);
            console.timeEnd("buckets");
            setPoints(markers)
        }
    }, [file1, file2]);

    useEffect(() => {

        const allowed = e => {
            if (e.timeDiff > timeFilter * 60) return false;
            const minYearUnix = moment("0101" + minYear, "DDMMYYYY").unix();
            const maxYearUnix = moment("0101" + (maxYear + 1), "DDMMYYYY").unix();
            return e.unix >= minYearUnix && e.unix < maxYearUnix;
        };

        setFilteredPoints(points.filter(allowed));
    }, [points, timeFilter, minYear, maxYear]);

    if (points.length === 0) {
        // return <LoadingScreen/>
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div style={{display: 'flex', height: '100vh'}}>
                <Map points={filteredPoints} clicked={setSelectedPoint} distanceFilterKm={distanceFilter / 1000}/>
                <Panel>
                    <SelectedPointInformation names={["ANdy", "Bob"]} point={selectedPoint}/>
                    <Filters distanceFilter={distanceFilter} maxYear={maxYear} minYear={minYear} setDistanceFilter={setDistanceFilter} setMinYear={setMinYear} setMaxYear={setMaxYear}
                             setTimeFilter={setTimeFilter} timeFilter={timeFilter}/>
                </Panel>
            </div>
        </MuiThemeProvider>
    );

};

const LoadingScreen = () => (
    <div style={{display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center', background: '#eee'}}>
        <Logo/>
        <p style={{marginTop: 128, fontSize: 80, color: primary}}>Intertwining your timelines</p>
        <img className="primary-tinted-image" src={require("./assets/loader.gif")}/>
    </div>
);

export default App;
