import moment from "moment";

const populateBuckets = (buckets, data, userName) => {
    const filtered = data.map(e => ({time: e.timestampMs / 1000, lat: e.latitudeE7 / 10000000, lng: e.longitudeE7 / 10000000}));

    filtered.forEach(e => {
        const latBucket = Math.round(e.lat * 1000);
        const lngBucket = Math.round(e.lng * 1000);

        const bucketName = `${latBucket}-${lngBucket}`;

        if (buckets[bucketName] == null) {
            buckets[bucketName] = {};
        }

        if (buckets[bucketName][userName] == null)
            buckets[bucketName][userName] = [];

        buckets[bucketName][userName].push(e);
    });

    console.log(Object.keys(buckets).length);
};

const haversineDistance = (a, b) => {
    const toRadian = angle => (Math.PI / 180) * angle;
    const distance = (a, b) => (Math.PI / 180) * (a - b);

    let lat1 = a.lat;
    let lat2 = b.lat;

    const lon1 = a.lng;
    const lon2 = b.lng;

    const dLat = distance(lat2, lat1);
    const dLon = distance(lon2, lon1);

    lat1 = toRadian(lat1);
    lat2 = toRadian(lat2);

    const ans =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.asin(Math.sqrt(ans));

    const RADIUS_OF_EARTH_IN_KM = 6371;
    return RADIUS_OF_EARTH_IN_KM * c;
};

const findClosestPair = (bucket0, bucket1) => {

    let a = 0;
    let b = 0;

    let m = bucket0.length;
    let n = bucket1.length;

    let result = Number.MAX_SAFE_INTEGER;
    let pair = null;

    while (a < m && b < n) {
        if (Math.abs(bucket0[a].time - bucket1[b].time) < result) {
            result = Math.abs(bucket0[a].time - bucket1[b].time);
            pair = {a: bucket0[a], b: bucket1[b]};
        }

        // Move Smaller Value
        if (bucket0[a].time < bucket1[b].time)
            a++;
        else
            b++;
    }

    return pair;
};

export const markersForFiles = (files) => {
    const buckets = {};
    files.map(e => JSON.parse(e)).map(e => e.locations).forEach((e, i) => populateBuckets(buckets, e, "person" + i));

    return Object.keys(buckets).map(key => {
        if (Object.keys(buckets[key]).length <= 1) return null;
        const bucket0 = Object.values(buckets[key])[0];
        const bucket1 = Object.values(buckets[key])[1];

        if (bucket0.length === 0 || bucket1.length === 0) return null;

        const closestPair = findClosestPair(bucket0, bucket1);
        const wasPerson0First = closestPair.a.time < closestPair.b.time;
        const bestRecord = {...closestPair, timeDiff: Math.abs(closestPair.a.time - closestPair.b.time)};

        const timeDiff = bestRecord.timeDiff;
        const distance = haversineDistance(bestRecord.a, bestRecord.b);
        const firstTime = Math.min(bestRecord.a.time, bestRecord.b.time);
        const date = moment.unix(firstTime).format("Do MMMM YYYY");

        const lat = (bestRecord.a.lat + bestRecord.b.lat) / 2;
        const lng = (bestRecord.a.lng + bestRecord.b.lng) / 2;

        return {date, unix: firstTime, distance, timeDiff, lat, lng, wasPerson0First};
    }).filter(e => e !== null);
};